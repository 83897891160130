// ** Checks if an object is empty (returns boolean)
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AWS from "aws-sdk";
import firebase from "@firebase/app";
import { NotificationManager } from "react-notifications";
// import Resizer from "react-image-file-resizer";

export const isUserLoggedIn = () => localStorage.getItem("userData");
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

const MySwal = withReactContent(Swal);
export const handleConfirmText = (props) => {
  return MySwal.fire({
    title: "login session has been expired ?",
    text: "login again to continue accessing your account !",
    icon: "warning",
    showCancelButton: false,
    confirmButtonText: "Yes, logout it!",
    customClass: {
      confirmButton: "btn btn-danger",
      cancelButton: "btn btn-outline-danger ml-1",
    },
    allowOutsideClick: false,
    buttonsStyling: false,
  }).then(function (result) {
    if (result.value) {
      firebase.auth().signOut();
      localStorage.removeItem("userData");
      window.localStorage.clear();
      window.localStorage.clear();
      window.location.href = "/";
    }
  });
};

const Axios = axios.create({
  baseURL: process.env.REACT_APP_ENVIRONMENT === "production" ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEVO,
});

Axios.interceptors.request.use(async (config) => {
  await new Promise((resolve) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(() => {
      unsubscribe();
      resolve();
    });
  });
  config.headers.Authorization = `Bearer ${await firebase
    .auth()
    .currentUser.getIdToken(false)}`;
  return config;
});

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (firebase.auth().currentUser === null || error.response.status === 401) {
      handleConfirmText();
    } else {
      return Promise.reject(error);
    }
  }
);

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ENVIRONMENT === "production" ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEVO,
});

export { Axios, AxiosInstance };

AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_S3_REGION
});

const s3 = new AWS.S3({
  apiVersion: "2012-10-17",
  params: { Bucket: process.env.REACT_APP_S3_BUCKET_NAME_COMPRESSION }
});

export const uploadImageToS3 = async (
  file,
  width,
  height,
  folderName,
  setisLoader
) => {
  const allowedExtensions = ["png", "jpg", "jpeg"];
  const fileExtension = file.name.split(".").pop().toLowerCase();

  if (!allowedExtensions.includes(fileExtension)) {
    NotificationManager.error(
      "Only JPG, PNG, JPEG file types are allowed",
      "Error"
    );
    return Promise.resolve("");
  }
  if (setisLoader) {
    setisLoader(true);
  }

  const randomNumber = Math.floor(1000 + Math.random() * 9000);

  // Get current timestamp
  const timestamp = Date.now();

  const filePath = `${process.env.REACT_APP_S3_DIR_NAME}/${folderName}/${timestamp}_${randomNumber}.${fileExtension}`;

  const params = {
    Key: filePath,
    Body: file,
    ACL: "private",
    ContentType: file.type,
    Metadata: {
      ...(height ? { "x-amz-meta-height": height.toString() } : {}),
      ...(width ? { "x-amz-meta-width": width.toString() } : {}),
    },
  };

  return new Promise((resolve, reject) => {
    s3.upload(params, (err, data) => {
      if (err) {
        NotificationManager.error("File upload failed", "Error");
        resolve("");
        if (setisLoader) {
          setisLoader(false);
        }
      } else {
        const webpLocation = data.key.replace(/\.[^/.]+$/, ".webp");
        const imgUrl = `${process.env.REACT_APP_S3_CLOUDFRONT_URL}/${webpLocation}`;

        setTimeout(() => {
          resolve(imgUrl);
          if (setisLoader) {
            setisLoader(false);
          }
        }, 10000);

      }
    });
  });
};


export const uploadPdfToS3 = async (file, folderName, setIsLoader) => {
  const s3 = new AWS.S3({
    apiVersion: "2012-10-17",
    params: { Bucket: process.env.REACT_APP_S3_BUCKET_NAME_CLOUDFRONT }
  });

  const allowedExtensions = ["pdf"];
  const fileExtension = file.name.split(".").pop().toLowerCase();

  if (!allowedExtensions.includes(fileExtension)) {
    NotificationManager.error(
      "Only PDF file types are allowed",
      "Error"
    );
    return Promise.resolve("");
  }

  if (setIsLoader) {
    setIsLoader(true);
  }

  const randomNumber = Math.floor(1000 + Math.random() * 9000);
  const timestamp = Date.now();
  const filePath = `${process.env.REACT_APP_S3_DIR_NAME}/${folderName}/${timestamp}_${randomNumber}.${fileExtension}`;

  const params = {
    Key: filePath,
    Body: file,
    ACL: "private",
    ContentType: file.type,
  };

  return new Promise((resolve, reject) => {
    s3.upload(params, (err, data) => {
      if (err) {
        NotificationManager.error("File upload failed", "Error");
        resolve("");
        if (setIsLoader) {
          setIsLoader(false);
        }
      } else {
        const pdfUrl = `${process.env.REACT_APP_S3_CLOUDFRONT_URL}/${data.Key}`;
        setTimeout(() => {
          resolve(pdfUrl);
          if (setIsLoader) {
            setIsLoader(false);
          }
        }, 4000);
      }
    });
  });
};


export const uploadVideo = (file, setisLoader) => {

  const s3 = new AWS.S3({
    apiVersion: "2012-10-17",
    params: { Bucket: process.env.REACT_APP_S3_BUCKET_NAME_CLOUDFRONT }
  });

  const allowedExtensions = ["mp4", "mkv"];
  const fileExtension = file.name.split(".").pop().toLowerCase();

  if (!allowedExtensions.includes(fileExtension)) {
    NotificationManager.error("Only mp4, mkv file types are allowed", "Error");
    return Promise.resolve("");
  }

  const params = {
    Key: `${process.env.REACT_APP_S3_DIR_NAME}/${Date.now()}_${file.name}`,
    Body: file,
    ACL: "private",
    ContentType: file.type,
  };

  setisLoader(true);

  return new Promise((resolve, reject) => {
    s3.upload(params, (err, data) => {
      setisLoader(false);
      if (err) {
        NotificationManager.error("File upload failed", "Error");
        resolve("");
      } else {
        const location = data["Key"];
        const url = `${process.env.REACT_APP_S3_CLOUDFRONT_URL}/${location}`;
        resolve(url);
      }
    });
  });
};

//-----------For Product master Description fields media upload (To set img url in func) -------------------

export const uploadImgToS3ForDesc = async (
  file,
  width,
  height,
  folderName,
  setisLoader,
  imgFun
) => {

  const allowedExtensions = ["png", "jpg", "jpeg"];
  const fileExtension = file.name.split(".").pop().toLowerCase();

  if (!allowedExtensions.includes(fileExtension)) {
    NotificationManager.error(
      "Only JPG, PNG, JPEG file types are allowed",
      "Error"
    );
    return Promise.resolve(""); // Return a resolved promise to handle the error
  }

  // Resize and optimize image
  // const resizedImage = await resizeImage(file, width, height);

  // Generate a 4-digit random number
  const randomNumber = Math.floor(1000 + Math.random() * 9000);

  // Get current timestamp
  const timestamp = Date.now();

  // Combine randomNumber and timestamp for the file name
  const filePath = `${process.env.REACT_APP_S3_DIR_NAME}/${folderName}/${timestamp}_${randomNumber}.${fileExtension}`;

  const params = {
    Key: filePath,
    Body: file,
    ACL: "private",
    ContentType: file.type,
    Metadata: {
      ...(height ? { "x-amz-meta-height": height.toString() } : {}),
      ...(width ? { "x-amz-meta-width": width.toString() } : {}),
    },
  };

  if (setisLoader) {
    setisLoader(true);
  }
  return new Promise((resolve, reject) => {
    s3.upload(params, (err, data) => {
      if (setisLoader) {
        setisLoader(false);
      }
      if (err) {
        NotificationManager.error("File upload failed", "Error");
        resolve("");
      } else {

        const webpLocation = data.key.replace(/\.[^/.]+$/, ".webp");
        const imgUrl = `${process.env.REACT_APP_S3_CLOUDFRONT_URL}/${webpLocation}`;
        setTimeout(() => {
          if (setisLoader) {
            setisLoader(false);
          }
          if (imgFun) {
            imgFun(imgUrl);
          }
          resolve(imgUrl); // Resolve after 2 seconds delay
        }, 1000);
      }
    });
  });
};

export const uploadVideoForDesc = (file, setisLoader, videoFun) => {

  const s3 = new AWS.S3({
    apiVersion: "2012-10-17",
    params: { Bucket: process.env.REACT_APP_S3_BUCKET_NAME_CLOUDFRONT }
  });

  const allowedExtensions = ["mp4", "mkv"];
  const fileExtension = file.name.split(".").pop().toLowerCase();

  if (!allowedExtensions.includes(fileExtension)) {
    NotificationManager.error("Only mp4, mkv file types are allowed", "Error");
    return Promise.resolve(""); // Return a resolved promise to handle the error
  }

  const params = {
    Key: `${process.env.REACT_APP_S3_DIR_NAME}/${Date.now()}_${file.name}`,
    Body: file,
    ACL: "private",
    ContentType: file.type,
  };
  setisLoader(true);
  return new Promise((resolve, reject) => {
    s3.upload(params, (err, data) => {
      setisLoader(false);
      if (err) {
        NotificationManager.error("File upload failed", "Error");
        resolve("");
      } else {
        const location = data["Key"];
        const url = `${process.env.REACT_APP_S3_CLOUDFRONT_URL}/${location}`;
        resolve(url);
        videoFun(url);
      }
    });
  });
};

export const deleteImageFromS3 = (e, filepath, setShowPreview) => {
  // const bucketName = "relocare";
  // const s3 = new AWS.S3({
  //   apiVersion: "2006-03-01",
  // });

  const bucketName = "relocare";
  const s3 = new AWS.S3({
    apiVersion: "2012-10-17",
  });
  const params = {
    Bucket: bucketName,
    Key: filepath?.split("/")?.slice(3)?.join("/"),
  };

  s3.deleteObject(params, (err, data) => {
    if (data) {
      // Handle UI updates after successful deletion
      setShowPreview(false);
    } else {
      console.log("Check if you have sufficient permissions : " + err);
    }
  });
};

export const UPLOAD_THUMBNAIL_IMAGE = async (
  event,
  setThumbnailFile,
  setShowPreviewThumbnail,
  setisLoader
) => {

  const dataArray = event.target;
  const data = {
    id: dataArray.getAttribute("id"),
    progressBar: dataArray.getAttribute("data-progress-bar"),
    appendClass: dataArray.getAttribute("data-append-class"),
    commonClass: dataArray.getAttribute("data-common-class"),
    subfolderName: dataArray.getAttribute("data-subfolder-name"),
    dataIsMultiple: dataArray.getAttribute("data-is-multiple"),
  };
  if (
    data.dataIsMultiple === "false" &&
    document.querySelectorAll("." + data.commonClass).length > 0
  ) {
    return false;
  }

  const fileUploadPath = dataArray.value;

  if (fileUploadPath === "") {
    return;
  }

  const extension = fileUploadPath
    .substring(fileUploadPath.lastIndexOf(".") + 1)
    .toLowerCase();

  if (extension === "png" || extension === "jpg" || extension === "jpeg") {

    const s3 = new AWS.S3({
      apiVersion: "2012-10-17",
      params: {
        Bucket: `${process.env.REACT_APP_S3_BUCKET_NAME_COMPRESSION}`,
      },
    });

    setisLoader(true);

    const today = new Date();
    const date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();

    for (let i = 0; i < event.target.files.length; i++) {
      const imageFile = event.target.files[i];

      if (imageFile.size <= 1000000) {
        const filePath = `${process.env.REACT_APP_S3_DIR_NAME}/products/${data.subfolderName}/${date}/${uuidv4()}.${extension}`;
        try {
          const reader = new FileReader();
          reader.onload = function (e) {
            const img = new Image();
            img.onload = async function () {
              const width = this.width;
              const height = this.height;

              const params = {
                Key: filePath,
                Body: imageFile,
                ACL: "private",
                ContentType: imageFile.type,
                Metadata: {
                  ...(height ? { "x-amz-meta-height": height.toString() } : {}),
                  ...(width ? { "x-amz-meta-width": width.toString() } : {}),
                },
              };

              await s3.upload(params).promise();
              const webpLocation = filePath.replace(/\.[^/.]+$/, ".webp");
              const webpUrl = `${process.env.REACT_APP_S3_CLOUDFRONT_URL}/${webpLocation}`;

              setTimeout(() => {
                NotificationManager.success(
                  "File uploaded successfully",
                  "Success"
                );
                setThumbnailFile(webpUrl);
                setShowPreviewThumbnail(true);
                setisLoader(false);
              }, 5000);
            };
            img.src = e.target.result;
          };
          reader.readAsDataURL(imageFile);
        } catch (err) {
          NotificationManager.error("Something went wrong!", "Error");
        }
      } else {
        dataArray.value = "";
        NotificationManager.error(
          "File size needs to be less than or equal to 1mb",
          "File name: " + imageFile.name
        );
        setisLoader(false);
      }
    }
  } else {
    dataArray.value = "";
    NotificationManager.error(
      "Only JPG or PNG or JPEG file types are allowed",
      "Error"
    );
  }
};

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

//Function for capitalize the text .
export const capitalizeText = (text) => {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

/********************upload video function for form information master**************/
export const UPLOAD_VIDEOS = async (
  event,
  setMediaFile,
  setShowPreview,
  setLoading
) => {

  const dataArray = event.target;
  const data = {
    id: dataArray.getAttribute("id"),
    progressBar: dataArray.getAttribute("data-progress-bar"),
    appendClass: dataArray.getAttribute("data-append-class"),
    commonClass: dataArray.getAttribute("data-common-class"),
    subfolderName: dataArray.getAttribute("data-subfolder-name"),
    dataIsMultiple: dataArray.getAttribute("data-is-multiple"),
  };

  if (
    data.dataIsMultiple === "false" &&
    document.querySelectorAll("." + data.commonClass).length > 0
  ) {
    return false;
  }

  const fileUploadPath = dataArray.value;

  if (fileUploadPath === "") {
    return;
  }

  const extension = fileUploadPath
    .substring(fileUploadPath.lastIndexOf(".") + 1)
    .toLowerCase();

  // Define allowed video extensions
  const allowedExtensions = ["mp4", "mov", "avi", "mkv"]; // Add more if needed
  // Check if the extension is in the allowed list
  if (!allowedExtensions.includes(extension)) {
    dataArray.value = ""; // Clear file input
    NotificationManager.error("Only video file types are allowed", "Error");
    return;
  }

  if (event.target.accept.includes("video/")) {
    const today = new Date();
    const date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();

    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];

      const isVideo = event.target.accept.includes("video/");

      if (isVideo && file.size <= 51000000) {
        const filePath = `vendor/${data.subfolderName
          }/${date}/${uuidv4()}.${extension}`;

        try {
          const xhr = new XMLHttpRequest();
          xhr.open(
            "PUT",
            `https://${process.env.REACT_APP_S3_BUCKET_NAME_CLOUDFRONT}/${filePath}`,
            true
          );
          xhr.setRequestHeader("Content-Type", "video/*");
          xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
              const percentage = Math.floor((event.loaded / event.total) * 100);
              setLoading(percentage);
            }
          };

          xhr.onload = () => {
            if (xhr.status === 200) {
              const fileUrl = `https://${process.env.REACT_APP_S3_BUCKET_NAME_CLOUDFRONT}/${filePath}`;
              NotificationManager.success(
                "Video uploaded successfully",
                "Success"
              );
              // You can use setState or some other state management mechanism to update UI
              setMediaFile(fileUrl);
              setShowPreview(true);
            } else {
              NotificationManager.error("Failed to upload video", "Error");
            }
            setLoading(0); // Set isLoading back to 0 after the upload is complete
          };

          xhr.onerror = () => {
            NotificationManager.error("Failed to upload video", "Error");
            setLoading(0); // Set isLoading back to 0 if there's an error
          };

          xhr.send(file);
        } catch (err) {
          NotificationManager.error("Something went wrong!", "Error");
          console.error(err);
          setLoading(0); // Set isLoading back to 0 if there's an error
        }
      } else {
        dataArray.value = "";
        NotificationManager.error(
          "Video size needs to be less than or equal to 50mb",
          "File name: " + file.name
        );
      }
    }
  } else {
    dataArray.value = "";
    NotificationManager.error("Only video file types are allowed", "Error");
  }
};

// copy to URL to clipboard
export const copyToClipboard = (text, msg) => {
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        NotificationManager.success(`${msg} has been copied.`);
      })
      .catch((error) => {
        NotificationManager.error("Failed to copy");
      });
  } else {
    // Fallback for browsers that don't support Clipboard API
    try {
      const input = document.createElement("textarea");
      input.value = text;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      NotificationManager.success(`${msg} has been copied.`);
    } catch (error) {
      NotificationManager.error("Failed to copy");
    }
  }
};

export const convertTextToBoldAndItalic = (text) => {
  if (!text) return "";

  let formattedText = text;

  // Replace [b] and [/b] tags with <b> tags
  formattedText = formattedText
    .replace(/\[b\]/g, "<b>")
    .replace(/\[\/b\]/g, "</b>");

  // Replace [i] and [/i] tags with <i> tags
  formattedText = formattedText
    .replace(/\[i\]/g, "<i>")
    .replace(/\[\/i\]/g, "</i>");

  return <div dangerouslySetInnerHTML={{ __html: formattedText }} />;
};

//----------Restrict negative values or accept only number --------//
export const handleOnlyNumbers = (event) => {
  const charCode = event.which ? event.which : event.keyCode;
  // Check if the character is a digit (0-9) or a period (.)
  if ((charCode < 48 || charCode > 57) && charCode !== 46) {
    event.preventDefault();
  }

  // Prevent period as the first character
  if (event.target.value === "" && charCode === 46) {
    event.preventDefault();
  }

  // Prevent multiple period characters
  if (charCode === 46 && event.target.value.includes(".")) {
    event.preventDefault();
  }
};

export const handleOnlyNumbersNoZero = (event) => {
  const charCode = event.which ? event.which : event.keyCode;
  // Check if the character is a digit (0-9) or a period (.)
  if ((charCode < 48 || charCode > 57) && charCode !== 46) {
    event.preventDefault();
  }

  // Prevent period as the first character
  if (event.target.value === "" && charCode === 46) {
    event.preventDefault();
  }

  // Prevent multiple period characters
  if (charCode === 46 && event.target.value.includes(".")) {
    event.preventDefault();
  }

  // Prevent 0 as the first character
  if (event.target.value === "" && charCode === 48) {
    event.preventDefault();
  }
};