// ** UseJWT import to get config
import { AxiosInstance } from "util/index";
import { NotificationManager } from "react-notifications";
import { firebase, auth } from "./../../../firebase";
import {
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
} from "./../Types";

// ** Handle User Login
function loginUserSuccess(dispatch, user, history) {
  dispatch({
    type: LOGIN_USER_SUCCESS,
    payload: user,
  });
  window.location.href = "/app/dashboard"; // history.push('/app/dashboard');
}

function loginUserFailure(dispatch, error) {
  dispatch({
    type: LOGIN_USER_FAILURE,
    payload: error,
  });
  NotificationManager.error(error.message);
}

export const handleLogin = (data, props, phone) => {
  return (dispatch) => {
    AxiosInstance.post(`/auth/loginUser`, {
      mobile_number: data.mobile_number,
      password: data.password,
    })
      .then((response) => {
        if (response && response.data && response.data.status === true) {
          if (
            response &&
            response.data &&
            (response.data.data.user_type === "Admin" ||
              response.data.data.user_type === "Staff")
          ) {
            localStorage.setItem(
              "authentication",
              response.data.authentication
            );
            firebase
              .auth()
              .signInWithCustomToken(response.data.data.token)
              .then((userCredential) => {
                // Signed in //
                localStorage.setItem("loginId", response.data.data._id);
                localStorage.setItem(
                  "userData",
                  JSON.stringify(response.data.data)
                );
                localStorage.setItem(
                  "user_id",
                  JSON.stringify(response.data.data._id)
                );

                loginUserSuccess(
                  dispatch,
                  localStorage.getItem("user_id"),
                  props.history
                );
                NotificationManager.success(response.data.message);
                // dispatch({
                //   type: "LOGIN",
                //   data,
                //   isPasswordExist: false
                //   // config,
                //   // [config.storageTokenKeyName]: data[config.storageTokenKeyName],
                //   // [config.storageRefreshTokenKeyName]: data[config.storageRefreshTokenKeyName]
                // });
              })
              .catch((error) => {
                loginUserFailure(dispatch, error);
                NotificationManager.error("Something Went Wong !");
              });
          } else {
            loginUserFailure(dispatch, "You don't have an access of web panel");
            NotificationManager.error("You don't have an access of web panel");
          }
        } else {
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        NotificationManager.error(error.response.data.message);
      });

    // ** Add to user, accessToken & refreshToken to localStorag
  };
};

// ** Handle User Logout
export const handleLogout = () => {
  return (dispatch) => {
    localStorage.removeItem("userData");
    window.localStorage.clear();
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.removeItem("userData");
        localStorage.removeItem("user_id");
        dispatch({ type: LOGOUT_USER });
        NotificationManager.success("User logout successfully!");
      })
      .catch((error) => {
        NotificationManager.error("Something Went Wrong !");
      });

    // ** Remove user, accessToken & refreshToken from localStorage
    // localStorage.removeItem("userData");
    // localStorage.removeItem('userData')
    // localStorage.removeItem(config.storageTokenKeyName)
    // localStorage.removeItem(config.storageRefreshTokenKeyName)
  };
};

export const handleForgotPassword = (props, data, phone) => {
  return async (dispatch) => {
    AxiosInstance.post(`/auth/checkMobileNumberExist`, {
      mobile_number: data.mobileNumber,
    })
      .then((response) => {
        if (response.data.data !== null) {
          // firebase.auth().settings.appVerificationDisabledForTesting = true;
          const verify = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container",
            {
              size: "invisible",
              badge: "inline",
            }
          );
          auth
            .signInWithPhoneNumber(data.mobileNumber, verify)
            .then((result) => {
              window.finalResult = result;
              //dispatch({ type: 'FORGOT_PASSWORD', showPassword: true});
              props.history.push({
                pathname: "/otp",
                state: {
                  id:
                    response &&
                    response.data &&
                    response.data.data &&
                    response.data.data._id,
                  mobile: data.mobileNumber,
                  status: true,
                  type_pass: "set",
                },
              });
              NotificationManager.success(
                `OTP has been sent to your number: ${data.mobileNumber}`
              );
            })
            .catch((err) => {
              NotificationManager.error("Something Went Wrong !");

              window.location.reload();
            });
        } else {
          NotificationManager.error("Mobile dose not registered");
        }
      })
      .catch((error) => {
        NotificationManager.error(error.response.data.message);
      });
  };
  /* return (dispatch) => {
    dispatch({ type: "Create_New", data });
  }; */
};

export const verifyOtp = (data, props) => {
  if (data.login_otp === null) return;
  window.finalResult
    .confirm(data.login_otp)
    .then(async (result) => {
      if (result) {
        props.history.push({
          pathname: "/setNewPassword",
          state: {
            id: props.location.state.id,
            status: true,
            type_pass: props.location.state.type_pass,
          },
        });
        NotificationManager.success("Otp verify successfully ");
      }
    })
    .catch((err) => {
      NotificationManager.error("OTP did't match please enter latest OTP!");
    });

  return (dispatch) => {
    dispatch({ type: "Otp", data });
  };
};
export const CreatePassword = (data, props) => {
  return async (dispatch) => {
    try {
      const response = await AxiosInstance.post("/auth/setNewPassword", {
        id: props && props.location && props.location.state.id,
        password: data.password,
        uuid: auth.currentUser.uid !== null ? auth.currentUser.uid : null,
      });
      NotificationManager.success(response.data.message);
      dispatch({ type: "CHANGE_PASSWORD" });
      props.history.push("/signin");
    } catch (error) {
      NotificationManager.error("Something Went Wrong !");
      dispatch({ type: "CHANGE_PASSWORD" });
    }
  };
};

export const checkMobileNumberExist = (data, props) => {
  return async (dispatch) => {
    AxiosInstance.post("/auth/checkMobileNumberExist", {
      mobile_number: data.mobile_number,
    })
      .then((response) => {
        if (response && response.data && response.data.status === true) {
          if (
            response &&
            response.data &&
            (response.data.data.user_type === "Admin" ||
              response.data.data.user_type === "Staff")
          ) {
            if (
              response &&
              response.data &&
              response.data.data.is_password_exist === false
            ) {
              const verify = new firebase.auth.RecaptchaVerifier(
                "recaptcha-container",
                {
                  size: "invisible",
                  badge: "inline",
                }
              );
              auth
                .signInWithPhoneNumber(data.mobile_number, verify)
                .then((result) => {
                  window.finalResult = result;
                  props.history.push({
                    pathname: "/otp",
                    state: {
                      id:
                        response &&
                        response.data &&
                        response.data.data &&
                        response.data.data._id,
                      mobile: data.mobile_number,
                      status: true,
                      type_pass: "set",
                    },
                  });

                  NotificationManager.success(
                    `OTP has been sent to your number: ${data.mobile_number}`
                  );
                })
                .catch((error) => {
                  NotificationManager.error("Something went wrong!");
                });
            } else {
              dispatch({
                type: "SET_IS_PASSWORD_EXIST",
                isPasswordExist: true,
              }); // update isPasswordExist state to true
            }
          } else {
            NotificationManager.error("You don't have access web panel");
          }
        } else {
          NotificationManager.error(
            response && response.data && response.data.data.message
          );
        }
      })
      .catch((error) => {
        NotificationManager.error(error.response.data.message);
      });
  };
};
