const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    allActiveCity: [],
    cityStateWise: [],
  };
  
  const brandReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_DATA_BRAND":
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        };
  
      case "GET_DATA_ALL_ACITVE_CITY":
        return {
          ...state,
          allActiveCity: action.data,
        };
  
      case "CREATE_BRAND":
        return { ...state };
      case "UPDATE_BRAND":
        return { ...state };
      case "DELETE_BRAND":
        return { ...state };
  
      default:
        return { ...state };
    }
  };
  export default brandReducer;
  