const initialState = {
  data: [],
  total: 1,
  params: {},
}

const feedsReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_FEEDS_ACCESS_CUSTOMERS':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'ADD_FEEDS_ACCESS':
      return { ...state }
    case "REMOVE_FEEDS_ACCESS":
      return { ...state };
    case 'GET_IMPORT_RESULTS':
      return {
        ...state,
        data: action.data
      }
    default:
      return { ...state }
  }
}

export default feedsReducer
