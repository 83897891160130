const initialState = {
  data: [],
  total: 1,
  params: {},
  adminOrderData: [],
  cityPaginatedData: [],
  invoice : {}
}

const adminOrderReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_DATA_ADMIN_ORDERS':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }

    case 'GET_ADMIN_ORDERS_BY_ID_DATA':
      return {
        ...state,
        adminOrderData: action.data
      }

      case 'GET_CITY_DATA':
      return {
        ...state,
        cityPaginatedData: action.data
      }

      case 'GET_INVOICE_DETAILS_BY_ORDER_ID':
      return {
        ...state,
        invoice: action.data
      }
   
    default:
      return { ...state }
  }
}
export default adminOrderReducer