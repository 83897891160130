const initialState = {
  data: [],
  total: 1,
  params: {},
  // allData: [],
  // designationData: [],
  // change_PasswordData:[],
  branchManagerData: [],
  branchData:[]
}

const branchReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_BRANCH_DATA':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'CREATE_BRANCH':
      return { ...state }
    case 'UPDATE_BRANCH':
      return { ...state }
    case "GET_DATA_BRANCH_MANAGER_USER":
      return {
        ...state,
        branchManagerData: action.data,
      };
   

    default:
      return { ...state }
  }
}
export default branchReducer
