/**
 * Tabs
 */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import { AppBar, Typography, Box, Grid, Toolbar } from "@material-ui/core";

// Component
import { getInfoByUrl, notificationSidebarAction } from "Redux/actions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { CircularProgress, IconButton } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box py={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  sidebarWrap: {
    width: 450,
    height: "100vh",
    overflowX: "hidden",
  },
  root: {
    "& >div": {
      "& >div:first-child": {
        backgroundColor: theme.palette.background.default,
        "& .Mui-selected": {
          backgroundColor: theme.palette.primary.main,
          "& .MuiTab-wrapper": {
            color: theme.palette.common.white,
          },
        },
      },
    },
    "& button": {
      minHeight: 48,
    },
  },
  closeButton: {
    marginRight: theme.spacing(2), // Add margin to create space between the tabs and the close button
  },
  title: {
    flexGrow: 1,
    color: theme.palette.common.white,
    textTransform: "capitalize",
  },
}));

export default function FullWidthTabs(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [isLoader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const notificationSidebar = settings.notificationSidebar;

  const [urlTitle, setUrlTitle] = useState("");
  const title = localStorage.getItem("pageTitle");

  // useEffect(() => {

  // 	setUrlTitle(title);
  // 	if (notificationSidebar) {
  // 	//For later use this 'url' dynamically.
  // 	  const url = window.location.pathname.split("/").pop();
  // 	  dispatch(getInfoByUrl(url, setLoader));
  // 	}
  //   }, [notificationSidebar, title]);
  useEffect(() => {
    setUrlTitle(title);
    if (notificationSidebar) {
      const url = window.location.pathname.split("/").pop();
      dispatch(getInfoByUrl(url, setLoader));
    }
  }, [notificationSidebar, title, dispatch]);

  const store = useSelector((state) => state.settings);

  //For Close button of sidebar
  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    const val = !notificationSidebar;
    dispatch(notificationSidebarAction(val));
  };

  return (
    //SideBar For IndfoData
    <div className={`${classes.sidebarWrap} sidebar-wrap-cong`}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {urlTitle}
          </Typography>
          <IconButton
            className={classes.closeButton}
            color="inherit"
            aria-label="close"
            onClick={toggleDrawer()}
          >
            <i className="material-icons-outlined">close</i>
          </IconButton>
        </Toolbar>
      </AppBar>
      {isLoader === true ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center" // Add alignItems property to center vertically
          height="100vh" // Set the height of the container to the viewport height to center vertically
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <SwipeableViews axis={theme.direction === "rtl" ? "x-reverse" : "x"}>
          <TabPanel>
            <div className="sidebar-widget-wrap">
              <Box className="quick-doc">
                <Box className="quick-doc-block">
                  <div className="quick-doc-title">
                    Title: {store.data.title}
                  </div>
                  {store.data &&
                    store.data.description &&
                    store.data.description.map((item, index) => (
                      <>
                        <Grid container>
                          <Grid item md={12}>
                            <h3>
                              <span className="font-italic font-weight-bold text-danger">
                                {item.field_name}
                              </span>
                              : {item.description}
                            </h3>
                          </Grid>
                        </Grid>
                      </>
                    ))}
                  <hr />
                  <div className="quick-doc-title">Video:</div>
                  {store?.data?.video && (
                    <video
                      id="video"
                      className="product-video"
                      data-url={store.data.video}
                      controls
                      style={{
                        maxHeight: "50%",
                        maxWidth: "100%",
                        boxShadow: "1px 1px 7px 0px #d8cfcf",
                        padding: "3px",
                      }}
                    >
                      <source src={store.data.video} type="video/mp4" />
                    </video>
                  )}
                  <hr />
                  <div className="quick-doc-title">Note:</div>
                  <h3 style={{ whiteSpace: "pre-wrap" }}>{store.data.note}</h3>
                </Box>
              </Box>
            </div>
          </TabPanel>
        </SwipeableViews>
      )}
    </div>
  );
}
