const initialState = {
  data: [],
  total: 1,
  params: {},
  schoolData: [],
  cityData: [],
  // allData: [],
  // designationData: [],
  // reportingManager: [],
  // change_PasswordData:[],
}

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_DATA_NOTIFICATION':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'CREATE_NOTIFICATION':
      return { ...state }
    case 'UPDATE_NOTIFICATION':
      return { ...state }
    case 'DELETE_CITY':
      return { ...state }
    case "GET_ACTIVE_SCHOOL_DATA":
      return {
        ...state,
        schoolData: action.data,
      }
    case 'GET_CITY':
      return {
          ...state,
          allData: action.allData,
          data: action.data,
          cityData: action.city.data
      }
    default:
      return { ...state }
  }
}
export default notificationReducer
