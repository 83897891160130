/**
 * Router service file
 */

// Async component
import {
  AsyncDashboard1Component,
  AsyncFormInformationComponent,
  AsyncUserMasterComponent,
  AsyncUserSettingsComponent,
  AsynCategoryComponent,
  AsynBranchComponent,
  AsynPromocodeComponent,
  AsynSettingComponent,
  AsynNotificationComponent,
  AsynProductComponent,
  //list
  // AsynOrderListComponent,
  AsynCustomerListComponent,
  // AsynProductAvailibilityUpdateComponent,
  AsynBranchSellsReportComponent,
  AsynSellsReportComponent,
  AsynSubCategoryComponent,
  AsyncBannerMasterComponent,
  AsynPopupComponent,
  AsynBlogsComponent,
  AsynCityComponent,
  AsynBrandComponent,
  AsynComboProductComponent,
  AsynSupplierComponent,
  AsynPurchaseComponent,
  AsynPurchaseHistoryComponent,
  AsynFaqCategoryComponent,
  AsynFaqComponent,
  AsynWarehouseComponent,
  AsynWarehouseOrdersComponent,
  AsynAdminOrdersComponent,
  AsynInventoryReportComponent,
  AsynDemandedProductsComponent,
  // AsynUnregisteredComponent,
  AsynPurchaseLogsComponent,
  AsynAbandonedCartComponent,
  AsynCustomersReportComponent,
  // AsynChildrensReportComponent,
  AsynSecondSubCategoryComponent,
  AsynTagComponent,
  AsynReplacementReportComponent,
  AsynWarehouseReplacementOrdersComponent,
  AsynFlavourVariationComponent,
  AsynFeedComponent,
  AsynEventComponent,
  AsynExpiryAlertReportComponent,
  AsynDeadStockReportComponent,
  AsynSalesReportComponent
} from "routes/ComponentLoader";

const permission = JSON.parse(localStorage.getItem("userData"))
  ? JSON.parse(localStorage.getItem("userData")).authentication
  : {};

const routes = [
  //Dashboard's routes & permissions.
  {
    path: "dashboard",
    component: AsyncDashboard1Component,
    meta: { permission: true },
  },
  //Master's routes & permissions.
  {
    path: "master/user",
    component: AsyncUserMasterComponent,
    meta: { permission: true },
  },
  {
    path: "master/tags",
    component: AsynTagComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Tag_master")
          : false,
    },
  },
  {
    path: "master/report",
    component: AsyncUserMasterComponent,
    meta: { permission: true },
  },
  {
    path: "master/formInformation",
    component: AsyncFormInformationComponent,
    meta: { permission: true },
  },
  {
    path: "master/banner",
    component: AsyncBannerMasterComponent,
    meta: { permission: true },
  },
  {
    path: "master/category",
    component: AsynCategoryComponent,
    meta: { permission: true },
  },
  {
    path: "master/sub-category",
    component: AsynSubCategoryComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("SubCategory_master")
          : false,
    },
  },
  {
    path: "master/branch",
    component: AsynBranchComponent,
    meta: { permission: true },
  },
  {
    path: "master/promocode",
    component: AsynPromocodeComponent,
    meta: { permission: true },
  },

  {
    path: "master/notification",
    component: AsynNotificationComponent,
    meta: { permission: true },
  },
  {
    path: "setting",
    component: AsynSettingComponent,
    meta: { permission: true },
  },
  {
    path: "product",
    component: AsynProductComponent,
    meta: { permission: true },
  },
  {
    path: "combo-product",
    component: AsynComboProductComponent,
    meta: { permission: true },
  },

  //list
  // {
  // 	path: 'views/reports/orders',
  // 	component: AsynOrderListComponent,
  // 	meta: { permission: true },
  // },
  // {
  // 	path: 'views/ProductAvalibilityUpdate',
  // 	component: AsynProductAvailibilityUpdateComponent,
  // 	meta: { permission: true },
  // },
  {
    path: "views/reports/BranchSales",
    component: AsynBranchSellsReportComponent,
    meta: { permission: true },
  },
  {
    path: "reports/Sales",
    component: AsynSellsReportComponent,
    meta: { permission: true },
  },
  {
    path: "master/blogs",
    component: AsynBlogsComponent,
    meta: { permission: true },
  },

  {
    path: "user-settings",
    component: AsyncUserSettingsComponent,
    meta: { permission: true },
  },
  {
    path: "master/popup",
    component: AsynPopupComponent,
    meta: { permission: true },
  },
  //    {
  // 		path: 'sample-form',
  //       component:AsyncSampleFormComponent
  // 	}
  {
    path: "master/city",
    component: AsynCityComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("City_master")
          : false,
    },
  },
  {
    path: "master/brand",
    component: AsynBrandComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Brand_master")
          : false,
    },
  },
  {
    path: "master/supplier",
    component: AsynSupplierComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Supplier_master")
          : false,
    },
  },
  {
    path: "purchase-inventory/purchase",
    component: AsynPurchaseComponent,
    meta: {
      permission:
        permission && permission.PurchaseInventory !== undefined
          ? permission &&
          permission.PurchaseInventory.includes("Purchase_master")
          : false,
    },
  },
  {
    path: "purchase-inventory/purchase-history",
    component: AsynPurchaseHistoryComponent,
    meta: {
      permission:
        permission && permission.PurchaseInventory !== undefined
          ? permission &&
          permission.PurchaseInventory.includes("Purchase_history")
          : false,
    },
  },
  {
    path: "purchase-inventory/inventory-report",
    component: AsynInventoryReportComponent,
    meta: {
      permission:
        permission && permission.PurchaseInventory !== undefined
          ? permission &&
          permission.PurchaseInventory.includes("Inventory_report")
          : false,
    },
  },
  {
    path: "master/faq-category",
    component: AsynFaqCategoryComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("FAQ_Category_master")
          : false,
    },
  },
  {
    path: "master/faq",
    component: AsynFaqComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("FAQ_master")
          : false,
    },
  },
  {
    path: "master/warehouse",
    component: AsynWarehouseComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Warehouse_master")
          : false,
    },
  },
  {
    path: "flavour-variation",
    component: AsynFlavourVariationComponent,
    meta: { permission: true },
  },
  {
    path: "master/feeds",
    component: AsynFeedComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Feeds_master")
          : false,
    },
  },
  {
    path: "master/event",
    component: AsynEventComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Event_master")
          : false,
    },
  },

  {
    path: "orders/warehouse-orders",
    component: AsynWarehouseOrdersComponent,
    meta: {
      permission:
        permission && permission.OrdersMaster !== undefined
          ? permission && permission.OrdersMaster.includes("Warehouse_orders_report")
          : false,
    },
  },
  {
    path: "orders/admin-orders",
    component: AsynAdminOrdersComponent,
    meta: {
      permission:
        permission && permission.OrdersMaster !== undefined
          ? permission && permission.OrdersMaster.includes("Admin_orders_report")
          : false,
    },
  },
  {
    path: "reports/demanded-products",
    component: AsynDemandedProductsComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.ReportMaster !== undefined
          ? permission && permission.ReportMaster.includes("Demanded_products")
          : false,
    },
  },
  // {
  //   path: "reports/unregistered-schools",
  //   component: AsynUnregisteredComponent,
  //   meta: {
  //     // permission: true
  //     permission:
  //       permission && permission.ReportMaster !== undefined
  //         ? permission &&
  //           permission.ReportMaster.includes("Unregistered_schools")
  //         : false,
  //   },
  // },
  {
    path: "reports/purchase-logs",
    component: AsynPurchaseLogsComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.ReportMaster !== undefined
          ? permission && permission.ReportMaster.includes("Purchase_logs")
          : false,
    },
  },
  {
    path: "reports/abandoned-cart",
    component: AsynAbandonedCartComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.ReportMaster !== undefined
          ? permission && permission.ReportMaster.includes("Abandoned_cart")
          : false,
    },
  },
  {
    path: "reports/customers-revenue",
    component: AsynCustomersReportComponent,
    meta: {
      permission:
        permission && permission.ReportMaster !== undefined
          ? permission && permission.ReportMaster.includes("CustomersRevenue")
          : false,
    },
  },

  {
    path: "reports/customers-list",
    component: AsynCustomerListComponent,
    meta: {
      permission:
        permission && permission.ReportMaster !== undefined
          ? permission && permission.ReportMaster.includes("CustomersList")
          : false,
    },
  },
  // {
  //   path: "reports/childrens",
  //   component: AsynChildrensReportComponent,
  //   meta: {
  //     permission:
  //       permission && permission.ReportMaster !== undefined
  //         ? permission && permission.ReportMaster.includes("Childrens")
  //         : false,
  //   },
  // },

  {
    path: "master/second-sub-category",
    component: AsynSecondSubCategoryComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("SecondSubCategory_master")
          : false,
    },
  },

  {
    path: "orders/replacement",
    component: AsynReplacementReportComponent,
    meta: {
      permission:
        permission && permission.OrdersMaster !== undefined
          ? permission && permission.OrdersMaster.includes("Reverse_orders_report")
          : false,
    },
  },
  {
    path: "orders/warehouse-replacement-orders",
    component: AsynWarehouseReplacementOrdersComponent,
    meta: {
      permission:
        permission && permission.OrdersMaster !== undefined
          ? permission && permission.OrdersMaster.includes("Warehouse_reverse_orders_report")
          : false,
    },
  },
  {
    path: "reports/expiry-alert-report",
    component: AsynExpiryAlertReportComponent,
    meta: {
      permission:
        permission && permission.ReportMaster !== undefined
          ? permission &&
          permission.ReportMaster.includes("Expiry_Alert_report")
          : false,
    },
  },
  {
    path: "reports/expired-product-report",
    component: AsynDeadStockReportComponent,
    meta: {
      permission:
        permission && permission.ReportMaster !== undefined
          ? permission &&
          permission.ReportMaster.includes("Expired_Product_report")
          : false,
    },
  },
  {
    path: "reports/sales-report",
    component: AsynSalesReportComponent,
    meta: {
      permission:
        permission && permission.ReportMaster !== undefined
          ? permission && permission.ReportMaster.includes("Sales_report")
          : false,
    },
  },
];

export default routes;
