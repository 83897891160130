const initialState = {
  data: [],
  total: 1,
  params: {},
  categoryData: [],
  subCategoryData: [],
  secondSubCategoryData: [],
  branchData: [],
  schoolData: [],
  brandData: [],
  tagsData: [],
  reviews: [],
  totalPages: 1,
  queryParams: {},
};

const prouductReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_PRODUCT":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "CREATE_PRODUCT":
      return { ...state };
    case "UPDATE_PRODUCT":
      return { ...state };

    case "GET_CATEGORY_DATA":
      return {
        ...state,
        categoryData: action.data,
      };
    case "GET_ACTIVE_SCHOOL_DATA":
      return {
        ...state,
        schoolData: action.data,
      };
    case "GET_ACTIVE_BRAND_DATA":
      return {
        ...state,
        brandData: action.data,
      };

    case "GET_SUB_CATEGORY_DATA":
      return {
        ...state,
        subCategoryData: action.data,
      };

    case "GET_SECOND_SUB_CATEGORY_DATA":
      return {
        ...state,
        secondSubCategoryData: action.data,
      };

    case "GET_BRANCH_DATA":
      return {
        ...state,
        branchData: action.data,
      };

    case "GET_ACTIVE_TAGS_DATA":
      return {
        ...state,
        tagsData: action.data,
      };

    case "CHANGE_STATUS":
      return { ...state };
      case "GET_PRODUCT_REVIEWS":
      return {
        ...state,
        allData: action.payload.totalReviews,
        reviews: action.payload.reviews,
        totalPages: action.payload.totalPages,
        queryParams: action.payload.queryParams,
      };
    case "DELETE_PRODUCT_REVIEW":
      return { ...state };
    default:
      return { ...state };
  }
};
export default prouductReducer;
