const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  // allActiveCity: [],
  // cityStateWise: [],
};

const tagReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_TAG":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };

    //   case "GET_DATA_ALL_ACITVE_CITY":
    //     return {
    //       ...state,
    //       allActiveCity: action.data,
    //     };

    case "CREATE_TAG":
      return { ...state };
    case "UPDATE_TAG":
      return { ...state };
    case "DELETE_TAG":
      return { ...state };

    default:
      return { ...state };
  }
};
export default tagReducer;
