/**
 * admin header component
 */
/* eslint-disable */
import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import NavListItem from "./NavListItem";
import { useSelector, useDispatch } from "react-redux";
import { Scrollbars } from 'react-custom-scrollbars';
import {
  toggleThirdMenu,
  toggleMenu,
  toggleFourthMenu,
  onLoadToggleMenu,
} from "Redux/actions";

function SidebarContent(props) {
  const menuListReducer = useSelector((state) => state.menuListReducer);
  const dispatch = useDispatch();
  const routes = menuListReducer.navLinks;
  const [navLinks, setNavLinks] = useState(routes);

  let currentURL = window.location.href;

  useEffect(() => {
    const currentURL = location.pathname;
    const newURL = currentURL.substring(0, currentURL.lastIndexOf('/'));
    const currentIndex = navLinks.findIndex((navLink) => navLink.path === newURL);
    onLoadToggle(currentIndex);
  }, [currentURL]);

  localStorage.setItem('id:selectedMenuIndex', menuListReducer.selectedMenuIndex);

  const onLoadToggle = (index) => {
    dispatch(onLoadToggleMenu(index));
    setNavLinks(routes);
  };

  const toggleMenufunction = (index) => {
    dispatch(toggleMenu(index));
    setNavLinks(routes);
  };


  const toggleThirdMenuAndCloseSidebar = (index) => {
    dispatch(toggleThirdMenu(index));
    setNavLinks(navLinks);

    if (props.closeSidebar) {
      props.closeSidebar();
    }
  };
  const toggleThirdMenufunction = (index) => {
    dispatch(toggleThirdMenu(index));
    setNavLinks(routes);
  };

  const toggleFourthMenufunction = (fourthindex) => {
    dispatch(toggleFourthMenu(fourthindex));
    setNavLinks(routes);
    if (props.closeSidebar) {
      props.closeSidebar();
    }
  };

  const { closeSidebar } = props;

  return (
    <div>
      <Scrollbars autoHide autoHideDuration={100} style={{ height: "80vh" }}>
        <List className="menu-wrap" style={{ padding: 0 }}>
          {navLinks && navLinks.map((_navLink, index) => {

            let child_routes = null;
            if (_navLink.child_routes && _navLink.child_routes.length > 0) {
              child_routes = _navLink.child_routes.filter((child) => child.permission);
            }
            return (
              <NavListItem
                menu={{
                  ..._navLink,
                  child_routes
                }}
                key={index}
                toggleMenu={() => toggleMenufunction(index)}
                toggleFourthMenu={(e) => toggleFourthMenufunction(e)}
                toggleThirdMenu={(e) => toggleThirdMenufunction(e)}
                toggleThirdMenuAndCloseSidebar={(e) =>
                  toggleThirdMenuAndCloseSidebar(e)
                }
                closeSidebar={closeSidebar}
              />
            );
          })}
        </List>
      </Scrollbars>
    </div>
  );
}

export default SidebarContent;
