const initialState = {
  data: [],
  total: 1,
  params: {},
  warehouseReplacementOrderData: [],
}

const warehouseReplacementOrdersReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_DATA_WAREHOUSE_REPLACEMENT_ORDERS':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }

    case 'GET_WAREHOUSE_REPLACEMENT_ORDERS_BY_ID_DATA':
      return {
        ...state,
        warehouseReplacementOrderData: action.data
      }
   
    default:
      return { ...state }
  }
}
export default warehouseReplacementOrdersReducer
