const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  // allActiveCity: [],
  // cityStateWise: [],
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_EVENT":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };

    case "CREATE_EVENT":
      return { ...state };
    case "UPDATE_EVENT":
      return { ...state };

    default:
      return { ...state };
  }
};
export default eventReducer;
